<template>
  <section class="productMain">
    <div v-if="item" :key="index" class="product">
      <div class="productDetails wow fadeInLeft" data-wow-delay="0.3s">
        <img :src="fileUrl" alt class="productImage" />
        <div class="productTitle">
          <h1>Kampanya</h1>
        </div>
        <!-- <div class="leftImage">
            <img src="/images/product/bg_left.png" />
        </div>-->

        <div class="productDesc">
          <p>{{ item.title }}</p>
        </div>
        <!-- <div class="rightImage">
            <img src="/images/product/bg_right.png" />
        </div>-->

        <div class="rotated"></div>
        <div class="productPrice">
          <h1>
            <span class="outer">
              <span class="inner">{{ item.price }} ₺</span>
            </span>
            - {{ item.priceWithDiscount }} ₺
          </h1>
        </div>
      </div>
      <div class="productTime wow fadeInRight" data-wow-delay="0.3s">
        <div class="time">{{ formatTime(item.displaySecond) }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import store from "../store/index";
import WOW from "wow.js";
new WOW().init();
export default {
  name: "product",
  props: ["data"],
  data() {
    return {
      index: -1,
      item: null,
      destroyed: false,
      fileUrl: ''
    };
  },
  created() {
    if (
      this.model != null &&
      this.model.list != null &&
      this.model.list.length > 0
    ) {
      if (this.index == -1) {
        this.item = this.model.list[++this.index];

        this.fileUrl = this.item.productFile && this.item.productFile.fileName ?
          (process.env.VUE_APP_BASE_CDN_URL + '/' + this.item.productFile.fileName) :
          (process.env.VUE_APP_BASE_CDN_URL + '/' + store.state.logo);
      }
      setInterval(() => {
        if (++this.index >= this.model.list.length) this.index = 0;
        this.item = this.model.list[this.index];

        this.fileUrl = this.item.productFile && this.item.productFile.fileName ?
          (process.env.VUE_APP_BASE_CDN_URL + '/' + this.item.productFile.fileName) :
          (process.env.VUE_APP_BASE_CDN_URL + '/' + store.state.logo);
      }, 15000);
    }
  },
  methods: {
    formatTime(displaySecond) {
      var sec_num = parseInt(displaySecond, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return minutes + ":" + seconds;
    }
  },
  computed: {
    model() {
      return this.$store.state.product;
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss">
@import "../assets/animate/animate.css";

.productMain {
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url("/images/product/bg_main.png");
  background-position: center;
  background-origin: initial;
  background-repeat: no-repeat;
  background-size: cover;
}

.productMain .productDetails {
  width: 55%;
  min-width: 450px;
  min-height: 450px;
  height: 100%;
  background-color: transparent;
  float: left;
  padding-left: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.productMain .productTime {
  height: 100%;
  width: 45%;
  min-width: 450px;
  min-height: 450px;
  background-image: url("/images/product/bg_time.png");
  float: right;
  background-size: contain;
  background-origin: initial;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin: 0;
  margin-top: 4%;
}

.productMain .product {
  margin: 0;
  position: absolute;
  top: 30%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  padding-top: 15%;
}

.productImage {
  height: 47%;
  border: 2px solid white;
  width: 55%;
  margin: auto;
}

.productDesc p {
  text-align: center;
  vertical-align: middle;
  padding-right: 25px;
  padding-left: 25px;
  font-size: 65px;
  font-family: "Montserrat-regular";
  text-transform: uppercase;
}

.productMain .time {
  text-align: center;
  width: 100px;
  position: absolute;
  left: 0;
  margin: auto;
  color: white;
  font-family: luckiest-guy;
  font-size: 110px;
  bottom: 21%;
  right: 115px;
}

.productMain .productTitle {
  font-size: 25px;
  background-image: url("/images/product/bg_title.png");
  width: 75%;
  text-align: center;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 10%;
  color: white;
  text-transform: uppercase;
}

.productMain .productTitle>h1 {
  font-size: 50px;
  padding: 10px;
}

.productMain .productPrice {
  background-image: url("/images/product/bg_price.png");
  font-size: 42px;
  color: white;
  width: 55%;
  text-align: center;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 10%;
  color: white;
  font-weight: bolder;
}

.productPrice h1 {
  font-size: 65px;
  font-family: "Montserrat-Bold";
}

.productMain .rotated {
  background-image: url(/images/product/rotated.png);
  height: 50px;
  position: absolute;
  left: 0;
  bottom: 30px;
  z-index: -1;
  left: 4%;
  background-size: 100% 100%;
  width: 96%;
}

.productMain span.inner {
  color: white;
}

.productMain span.outer {
  color: red;
  text-decoration: line-through;
}

.productMain .leftImage>img {
  width: 25px;
  height: 228px;
  position: absolute;
  bottom: 108px;
}

.productMain .productDesc {
  background-image: url("/images/product/bg_details.png");
  text-align: center;
  padding: 10px;
  font-size: 42px;
  width: 100%;
  height: 33%;
  max-height: 400px;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productMain .rightImage>img {
  width: 25px;
  height: 223px;
  position: absolute;
  bottom: 114px;
  right: -9px;
}
</style>